import React from 'react';

import css from './DiceIcon.module.css';

const patterns = {
    1: [4],
    2: [1, 7],
    3: [1, 4, 7],
    4: [1, 2, 6, 7],
    5: [1, 2, 4, 6, 7],
    6: [1, 2, 3, 5, 6, 7],
}

const DiceIcon = ({ value }) => {
    let pips = patterns[value];

    return (
        <div className={css.dice}>
            {pips.map(pip => (
                <span className={css[`pip${pip}`]} />
            ))}
        </div>
    )
}

export default DiceIcon;