import React from 'react';
import loadable from '@loadable/component';

import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { camelize } from '../../util/string';
import { propTypes } from '../../util/types';

import FallbackPage from './FallbackPage';
import { ASSET_NAME } from './LandingPage.duck';

import css from './LandingPage.module.css';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import { H3, Page, LayoutSingleColumn } from '../../components';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import FooterContainer from '../FooterContainer/FooterContainer';

import SearchResultsPanel from '../SearchPage/SearchResultsPanel/SearchResultsPanel';
import AnyoneCanSell from './AnyoneCanSell';

const PageBuilder = loadable(() =>
  import(/* webpackChunkName: "PageBuilder" */ '../PageBuilder/PageBuilder')
);

export const LandingPageComponent = props => {
  const { pageAssetsData, inProgress, error } = props;

  return (
    <PageBuilder
      className={css.hero}
      pageAssetsData={pageAssetsData?.[camelize(ASSET_NAME)]?.data}
      inProgress={inProgress}
      error={error}
      fallbackPage={<FallbackPage error={error} />}
      content={(
        <div>
          <AnyoneCanSell />
          <div className={css.featuredGames} key="featured-listings">
              <div className={css.searchHeader}>
                <H3>Featured Listings</H3>
                <a className={css.viewMore} href="/s?sort=-pub_featured">View More →</a>
              </div>
              <SearchResultsPanel
                  listings={props.featuredListings}
                  search={null}
                  isMapVariant={false}
                />
            </div>
            <div className={css.featuredGames} key="newest-listings">
              <div className={css.searchHeader}>
                <H3>Newest Listings</H3>
                <a className={css.viewMore} href="/s?sort=createdAt">View More →</a>
              </div>
              <SearchResultsPanel
                  listings={props.newestListings}
                  search={null}
                  isMapVariant={false}
                />
            </div>
            {/* <div className={css.featuredGames} key="featured-accessories">
              <div className={css.searchHeader}>
                <H3>Gaming Accessories</H3>
                <a className={css.viewMore} href="/accessories">View More →</a>
              </div>
              <SearchResultsPanel
                  listings={props.featuredAccessories}
                  search={null}
                  isMapVariant={false}
                />
            </div>  */}
          </div>
    )}
    />
  );
};

LandingPageComponent.propTypes = {
  pageAssetsData: object,
  inProgress: bool,
  error: propTypes.error,
};

const mapStateToProps = state => {
  const { pageAssetsData, inProgress, error } = state.hostedAssets || {};
  const featuredListings = getListingsById(state, state.LandingPage.featuredResultIds);
  const newestListings = getListingsById(state, state.LandingPage.newestResultIds);
  const featuredAccessories = getListingsById(state, state.LandingPage.accessoriesResultIds);
  return { pageAssetsData, inProgress, error, featuredListings, newestListings, featuredAccessories };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(connect(mapStateToProps))(LandingPageComponent);

export default LandingPage;
