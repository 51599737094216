import React from 'react';

import { H4, H3, Button, NamedLink } from '../../components';

import css from './AnyoneCanSell.module.css';
import DiceIcon from '../../components/DiceIcon/DiceIcon';


const AnyoneCanSell = () => {

    return (
        <div className={css.root}>
            <div className={css.header}>
                <H3>Anyone Can Sell on Dicemates</H3>
                <NamedLink className={css.createListingLink} name="NewListingPage">
                    <Button className={css.actionButton}>
                        Sell Your Games
                    </Button>
                </NamedLink>
            </div>
            <div className={css.content}>
                <div className={css.item} style={{ backgroundColor: '#B62C3F' }}>
                    <div className={css.dice}></div>
                    <div className={css.itemContent}>
                        <H4 className={css.itemTitle}>List It</H4>
                        <div>Easily list your board games or handmade upgrades and accessories.</div>
                    </div>
                </div>
                <div className={css.item} style={{ backgroundColor: '#CC4F42' }}>
                    <div className={css.dice2}></div>
                    <div className={css.itemContent}>
                        <H4 className={css.itemTitle}>Sell It</H4>
                        <div>Gamers from all over the country search for games on Dicemates.</div>
                    </div>
                </div>
                <div className={css.item} style={{ backgroundColor: '#DF7B40' }}>
                    <div className={css.dice3}><div className={css.pip}/></div>
                    <div className={css.itemContent}>
                        <H4 className={css.itemTitle}>Ship It</H4>
                        <div>Dicemates handles the transaction process so you can buy and sell with confidence.</div>
                    </div>
                </div>
            </div>
          </div>
    );
}

export default AnyoneCanSell;